import React, { useState } from "react";
import Layout from "../Layout";
import { Box, TableContainer, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { DataGrid } from "@mui/x-data-grid";
import { getShowDeleted } from "../../utils/utils";
import Popper from '@mui/material/Popper';
function isOverflown(element) {
    return (element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth);
}
var GridCellExpand = React.memo(function GridCellExpand(props) {
    var width = props.width, value = props.value;
    var wrapper = React.useRef(null);
    var cellDiv = React.useRef(null);
    var cellValue = React.useRef(null);
    var _a = React.useState(null), anchorEl = _a[0], setAnchorEl = _a[1];
    var _b = React.useState(false), showFullCell = _b[0], setShowFullCell = _b[1];
    var _c = React.useState(false), showPopper = _c[0], setShowPopper = _c[1];
    var handleMouseEnter = function () {
        var isCurrentlyOverflown = isOverflown(cellValue.current);
        setShowPopper(isCurrentlyOverflown);
        setAnchorEl(cellDiv.current);
        setShowFullCell(true);
    };
    var handleMouseLeave = function () {
        setShowFullCell(false);
    };
    React.useEffect(function () {
        if (!showFullCell) {
            return undefined;
        }
        function handleKeyDown(nativeEvent) {
            // IE11, Edge (prior to using Bink?) use 'Esc'
            if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
                setShowFullCell(false);
            }
        }
        document.addEventListener('keydown', handleKeyDown);
        return function () {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [setShowFullCell, showFullCell]);
    return (React.createElement(Box, { ref: wrapper, onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, sx: {
            alignItems: 'center',
            lineHeight: '24px',
            width: '100%',
            height: '100%',
            position: 'relative',
            display: 'flex',
        } },
        React.createElement(Box, { ref: cellDiv, sx: {
                height: '100%',
                width: width,
                display: 'block',
                position: 'absolute',
                top: 0,
            } }),
        React.createElement(Box, { ref: cellValue, sx: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' } }, value),
        showPopper && (React.createElement(Popper, { open: showFullCell && anchorEl !== null, anchorEl: anchorEl, style: { width: width, marginLeft: -17 } },
            React.createElement(Paper, { elevation: 1, style: { minHeight: wrapper.current.offsetHeight - 3 } },
                React.createElement(Typography, { variant: "body2", style: { padding: 8 } }, value))))));
});
function renderCellExpand(params) {
    console.log(params);
    return (React.createElement(GridCellExpand, { value: params.value || '', width: params.colDef.computedWidth }));
}
var columns = [
    {
        field: "id",
        headerName: "ID",
        editable: false,
        flex: .5,
    },
    {
        field: "created_at",
        headerName: "Date",
        editable: false,
        flex: .5,
        renderCell: function (rowValues) {
            return new Date(rowValues.row.created_at).toDateString();
        },
    },
    {
        field: "description",
        headerName: "Description",
        editable: false,
        flex: .5,
    },
    {
        field: "full_name",
        headerName: "Member",
        editable: false,
        flex: .5,
    },
    {
        field: "new",
        headerName: "New",
        flex: 1,
        renderCell: renderCellExpand
        /*renderCell: (rowValues) =>
            JSON.stringify(rowValues.row.properties.attributes)*/
    },
    {
        field: "old",
        headerName: "Old",
        flex: 1,
        renderCell: renderCellExpand
    }
];
var MemberActivityLog = function (_a) {
    var response_data = _a.response_data, auth = _a.auth;
    var user = response_data.user;
    var _b = useState(true), menuOpen = _b[0], toggleMenuOpen = _b[1];
    var _c = useState(function () { return getShowDeleted(); }), showDeleted = _c[0], setShowDeleted = _c[1];
    var max_rows = 100;
    var member = response_data.member, all_documents = response_data.all_documents, activity_log = response_data.activity_log;
    var num_rows = response_data["activity_log"].length;
    var rows = [];
    var _d = React.useState(100), pageSize = _d[0], setPageSize = _d[1];
    return (React.createElement(Layout, { title: "Agent Activity Log", menuOpen: menuOpen, toggleMenuOpen: toggleMenuOpen },
        React.createElement(Grid, { container: true },
            num_rows == max_rows && (React.createElement("div", null,
                React.createElement("em", null,
                    "Please note: A maximum of ",
                    num_rows,
                    " results are shown below, and some results may be missing."))),
            React.createElement(Grid, { container: true, xs: 12, justifyContent: "center", alignContent: "center", spacing: 4 })),
        React.createElement(Grid, { container: true, m: 1, p: 1, xs: 12 },
            React.createElement("h3", null,
                user.first_name,
                " ",
                user.last_name,
                "'s Activity Log"),
            React.createElement("br", null),
            React.createElement(TableContainer, { component: Paper, elevation: 3 },
                React.createElement(DataGrid, { rows: activity_log, columns: columns, disableColumnSelector: true, disableSelectionOnClick: true, pageSize: pageSize, rowsPerPageOptions: [100, 50, 10], onPageSizeChange: function (newPageSize) { return setPageSize(newPageSize); }, autoHeight: true, hideFooter: false, pagination: true, sx: {
                        ".MuiTablePagination-displayedRows": {
                            marginTop: "14px",
                        },
                        ".MuiTablePagination-selectLabel": {
                            marginTop: "14px",
                        },
                    } })))));
};
export default MemberActivityLog;
