import React from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../../css/app.css";
import GenericNameForm from "./GenericNameForm";
import GenericPhoneInput from "./GenericPhoneInput";
import { memberFormOptions } from "../utils/utils";
import GenericControllerTextInput from "./GenericControllerTextInput";
var MemberInfoForm = function (props) {
    var _a, _b, _c, _d;
    console.log(props);
    var member = props.member, errors = props.errors, control = props.control, _e = props.data, data = _e === void 0 ? {} : _e;
    return (React.createElement(Grid, { xs: 12, spacing: 2 },
        React.createElement(Grid, { xs: 12 },
            React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "Member Information")),
        React.createElement(GenericNameForm, { control: control, member: member, errors: errors, options: memberFormOptions, data: data }),
        React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericControllerTextInput, { control: control, defaultValue: (_a = member === null || member === void 0 ? void 0 : member.email) !== null && _a !== void 0 ? _a : "", type: "email", label: "Email", name: "email", rules: { required: false }, error: "email" in errors })),
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericControllerTextInput, { control: control, type: "date", label: "Birth Date", name: "birth_date", rules: { required: false }, defaultValue: (_b = member === null || member === void 0 ? void 0 : member.birth_date) !== null && _b !== void 0 ? _b : "", error: "birth_date" in errors, shrinkLabel: true }))),
        React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericPhoneInput, { name: "phone", label: "Phone", defaultValue: (_c = member === null || member === void 0 ? void 0 : member.phone) !== null && _c !== void 0 ? _c : "", control: control, helperText: "Please provide full phone (###) ###-####.", required: true })),
            React.createElement(Grid, { xs: 12, md: 6 },
                React.createElement(GenericPhoneInput, { label: "Alt. Phone 1", defaultValue: (_d = member === null || member === void 0 ? void 0 : member.phone_alt_1) !== null && _d !== void 0 ? _d : "", name: "phone_alt_1", control: control, required: false, helperText: "Please provide full phone (###) ###-####." })))));
};
export default MemberInfoForm;
