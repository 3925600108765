import { snakeCase } from "lodash";
export function handleActiveSection(fn, section) {
    if (!fn)
        return;
    fn(section);
}
export function validatePhone(phone) {
    if (!phone)
        return true;
    if (phone.length === 0)
        return true;
    var regExp = /[\(\)\s\-\#]/g;
    return phone.replace(regExp, "").length === 10;
}
export function validateZip(zip) {
    if (!zip)
        return true;
    if (zip.length === 0)
        return true;
    var regExp = /[\(\)\s\-\#]/g;
    var unformattedZip = zip.replace(regExp, "");
    return unformattedZip.length === 5 || unformattedZip.length === 9;
}
export function validateCreditCard(cardNumber) {
    var regExp = /[\(\)\s\-\#]/g;
    var unformatted = cardNumber.replace(regExp, "");
    return unformatted.length === 15 || unformatted.length === 16;
}
export function isAmexCard(cardNumber) {
    if (cardNumber.startsWith("34")) {
        return true;
    }
    if (cardNumber.startsWith("37")) {
        return true;
    }
    return false;
}
export var mailingAddressOptions = {
    title: { name: "Mailing Address", required: false },
    street_1: {
        name: "ship_address_1",
        required: false,
        default: "ship_address_1",
    },
    street_2: {
        name: "ship_address_2",
        required: false,
        default: "ship_address_2",
    },
    city: { name: "ship_city", required: false, default: "ship_city" },
    state: { name: "ship_state_id", required: false, default: "ship_state_id" },
    zip: { name: "ship_zip", required: false, default: "ship_zip" },
    country: { name: "ship_country", required: false, default: "ship_country" },
};
export var brokerAddressOptions = {
    title: { name: "Address", required: false },
    street_1: {
        name: "broker[address_1]",
        required: false,
        default: "address_1",
    },
    street_2: {
        name: "broker[address_2]",
        required: false,
        default: "address_2",
    },
    city: { name: "broker[city]", required: false, default: "city" },
    state: { name: "broker[state_id]", required: false, default: "state_id" },
    county: { name: "broker[county]", required: false, default: "county" },
    zip: { name: "broker[zip]", required: false, default: "zip" },
    country: { name: false, required: false, default: false },
};
export var residentialAddressOptions = {
    title: { name: "Residential Address", required: false },
    street_1: {
        name: "res_address_1",
        required: false,
        default: "res_address_1",
    },
    street_2: {
        name: "res_address_2",
        required: false,
        default: "res_address_2",
    },
    city: { name: "res_city", required: false, default: "res_city" },
    state: { name: "res_state_id", required: false, default: "res_state_id" },
    county: { name: "res_county", required: false, default: "res_county" },
    zip: { name: "res_zip", required: false, default: "res_zip" },
    country: { name: "res_country", required: false, default: "res_country" },
};
export var employerAddressOptions = {
    title: { name: false, required: false },
    street_1: {
        name: "employer_address_1",
        required: false,
        default: "employer_address_1",
    },
    street_2: {
        name: "employer_address_2",
        required: false,
        default: "employer_address_2",
    },
    city: { name: "employer_city", required: false, default: "employer_city" },
    state: {
        name: "employer_state_id",
        required: false,
        default: "employer_state_id",
    },
    zip: { name: "employer_zip", required: false, default: "employer_zip" },
    country: { name: false, required: false, default: false },
};
export var primaryBeneficiaryOption = {
    name: { name: "beneficiary_p_name", label: "Primary Beneficiary" },
    percent: {
        name: "beneficiary_p_percent",
        label: "Primary Beneficiary Percent",
    },
    relation: {
        name: "beneficiary_p_relation",
        label: "Primary Beneficiary Relationship",
    },
    birth_date: {
        name: "beneficiary_p_birth_date",
        label: "Primary Beneficiary Birthdate",
    },
};
export var contingentBeneficiaryOption = {
    name: { name: "beneficiary_c_name", label: "Primary Contingent" },
    percent: {
        name: "beneficiary_c_percent",
        label: "Primary Contingent Percent",
    },
    relation: {
        name: "beneficiary_c_relation",
        label: "Primary Contingent Relationship",
    },
    birth_date: {
        name: "beneficiary_c_birth_date",
        label: "Primary Contingent Birthdate",
    },
};
export var memberFormOptions = {
    prefix: {
        member: "prefix",
        name: "prefix",
        lead: "prefix",
        application: "prefix",
        policy: "prefix",
        label: "Prefix",
        required: false,
        focus: false,
    },
    first_name: {
        member: "first_name",
        name: "first_name",
        lead: "first_name",
        application: "first_name",
        policy: "first_name",
        label: "First Name",
        required: true,
        focus: true,
    },
    middle_name: {
        member: "middle_name",
        name: "middle_name",
        lead: "middle_name",
        application: "middle_name",
        policy: "middle_name",
        label: "Middle Name",
        required: false,
        focus: false,
    },
    last_name: {
        member: "last_name",
        name: "last_name",
        lead: "last_name",
        application: "last_name",
        policy: "last_name",
        label: "Last Name",
        required: true,
        focus: false,
    },
    suffix: {
        member: "suffix",
        name: "suffix",
        lead: "suffix",
        application: "suffix",
        policy: "suffix",
        label: "Suffix",
        required: false,
        focus: false,
    },
};
export var spouseFormOptions = {
    prefix: {
        member: "spouse_prefix",
        name: "spouse_prefix",
        lead: "spouse_prefix",
        application: "spouse_prefix",
        policy: "spouse_prefix",
        label: "Spouse/Family Member Prefix",
        required: false,
        focus: false,
    },
    first_name: {
        member: "spouse_first_name",
        name: "spouse_first_name",
        lead: "spouse_first_name",
        application: "first_name",
        policy: "first_name",
        label: "Spouse/Family Member First Name",
        required: false,
        focus: false,
    },
    middle_name: {
        member: "spouse_middle_name",
        name: "spouse_middle_name",
        lead: "spouse_middle_name",
        application: "middle_name",
        policy: "middle_name",
        label: "Spouse/Family Member Middle Name",
        required: false,
    },
    last_name: {
        name: "spouse_last_name",
        member: "spouse_last_name",
        lead: "spouse_last_name",
        application: "last_name",
        policy: "last_name",
        label: "Spouse/Family Member Last Name",
        required: false,
    },
    suffix: {
        member: "spouse_suffix",
        name: "spouse_suffix",
        lead: "spouse_suffix",
        application: "spouse_suffix",
        policy: "spouse_suffix",
        label: "Spouse/Family Member Suffix",
        required: false,
        focus: false,
    },
    birth: {
        name: "spouse_birth_date",
        member: "spouse_birth_date",
        lead: "spouse_birth_date",
        application: "birth_date",
        policy: "birth_date",
        label: "Spouse/Family Member Birth Date",
        required: false,
    },
};
export function setLead(lead) {
    var _a, _b;
    if (!lead)
        return "0";
    if ((lead === null || lead === void 0 ? void 0 : lead.lead_type_id) === 15)
        return (_b = (_a = lead === null || lead === void 0 ? void 0 : lead.extra_data_json) === null || _a === void 0 ? void 0 : _a.quick_lead_type) !== null && _b !== void 0 ? _b : 15;
    return lead === null || lead === void 0 ? void 0 : lead.lead_type_id;
}
export function combineName(data) {
    var fullName = data.first_name + " ";
    fullName += (data === null || data === void 0 ? void 0 : data.middle_name) + " ";
    fullName += data.last_name;
    //replace double spaces
    fullName = fullName.replace(/\s\s+/g, " ");
    return fullName;
}
export function getStateById(id, states) {
    if (!id) {
        return { name: "" };
    }
    return states.filter(function (state) { return state.id == id; }).pop();
}
export function agentHasRole(user, roleName) {
    var _a;
    if (((_a = user === null || user === void 0 ? void 0 : user.roles) === null || _a === void 0 ? void 0 : _a.length) === 0)
        return false;
    for (var _i = 0, _b = user.roles; _i < _b.length; _i++) {
        var role = _b[_i];
        if (role.name === roleName) {
            return true;
        }
    }
    return false;
}
export function agentNumRoles(user) {
    var _a;
    return (_a = user === null || user === void 0 ? void 0 : user.roles) === null || _a === void 0 ? void 0 : _a.length;
}
export function agentHasPermission(user, permissionName) {
    var _a;
    if (((_a = user === null || user === void 0 ? void 0 : user.permissions) === null || _a === void 0 ? void 0 : _a.length) === 0)
        return false;
    for (var _i = 0, _b = user.permissions; _i < _b.length; _i++) {
        var permission = _b[_i];
        if (permission.name === permissionName) {
            return true;
        }
    }
    return false;
}
export function hasMenuPermission(auth, permissionName) {
    for (var _i = 0, _a = auth.can; _i < _a.length; _i++) {
        var permission = _a[_i];
        if (permission.name === permissionName) {
            return true;
        }
    }
    return false;
}
export function hasPermission(auth, permissionName) {
    if (auth.admin) {
        return true;
    }
    for (var _i = 0, _a = auth.can; _i < _a.length; _i++) {
        var permission = _a[_i];
        if (permission.name === permissionName) {
            return true;
        }
    }
    return false;
}
export function hasOrderEditAccess(auth, data) {
    if (auth.admin) {
        return true;
    }
    var created_by = data.created_by;
    if (hasPermission(auth, "edit all-orders")) {
        return true;
    }
    if ([created_by].includes(auth.user.id)) {
        return true;
    }
    var subordinates = auth.subordinates.map(function (el) { return el.id; });
    if (subordinates.includes(created_by)) {
        return true;
    }
    return false;
}
export function hasAccessTo(auth, data) {
    if (auth.admin) {
        return true;
    }
    if (hasPermission(auth, "edit all-forms")) {
        return true;
    }
    var transfer_user_id = data.transfer_user_id;
    if ([transfer_user_id].includes(auth.user.id)) {
        return true;
    }
    var subordinates = auth.subordinates.map(function (el) { return el.id; });
    if (subordinates.includes(transfer_user_id)) {
        return true;
    }
    return false;
}
export var filterDeletedAppables = function (el, showDeleted) { var _a; return showDeleted ? true : ![16, 18].includes((_a = el === null || el === void 0 ? void 0 : el.appable) === null || _a === void 0 ? void 0 : _a.disposition_id); };
export var filterDeletedApps = function (el, showDeleted) {
    return showDeleted ? true : ![16, 18].includes(el === null || el === void 0 ? void 0 : el.disposition_id);
};
export var filterDeletedLeads = function (el, showDeleted) {
    return showDeleted ? true : !["deleted"].includes(el === null || el === void 0 ? void 0 : el.status);
};
export var filterDeletedPolicies = function (el, showDeleted) {
    return showDeleted ? true : !["rejected", "cancelled"].includes(el === null || el === void 0 ? void 0 : el.status);
};
export var filterDeletedDonations = function (el, showDeleted) {
    return showDeleted ? true : !["deleted"].includes(el === null || el === void 0 ? void 0 : el.status);
};
export var getShowDeleted = function () {
    return localStorage.getItem("showDeleted")
        ? localStorage.getItem("showDeleted") == "true"
        : true;
};
export var formatMemberStatus = function (status, mergedToId, leave_reason) {
    if (mergedToId === void 0) { mergedToId = null; }
    if (mergedToId) {
        return "merged";
    }
    if (!status) {
        return "";
    }
    if (status === "deleted") {
        if (leave_reason) {
            return "cancelled";
        }
        return "inactive";
    }
    return status;
};
export var formatAppType = function (type) {
    return snakeCase(String(type)).replace("app_models_", "") + "s";
};
export var capitalizeFirstLetter = function (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
export var fullNameWithPrefixSuffix = function (full_name, prefix, suffix) {
    var name = prefix ? prefix + " " + full_name : full_name;
    suffix ? (name += " " + suffix) : null;
    return name;
};
